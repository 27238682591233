@import '../../client/style/color.scss';

.notfound_page {
    text-align: center;
    padding: 30vh 16px;
    background-color: $dark_blue;

    h3 {
        font-size: 52px;
        margin-bottom: 24px;
        font-weight: 700;
        color: $white;

        @media screen and (max-width: 600px) {
            font-size: 36px;
        }
    }

    p {
        font-size: 18px;
        margin-bottom: 16px;
        color: $white;

        @media screen and (max-width: 600px) {
            font-size: 14px;
        }
    }

    a {
        text-decoration: underline;
        color: $white;
    }
}