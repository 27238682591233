$main_color: #681CE6;
$primary_color: #FE8F31;
$secondary_color: #001456;

// $red: #DD4C50;

$white: #fff;
$black: rgb(19, 19, 19);

$text_lite: rgb(144, 144, 144);
$border_lite: rgb(230, 230, 230);
$row_lite: rgb(241, 241, 240);

$light_blue: rgb(191, 209, 255);
$orange: rgb(255, 132, 19);
$orange_gradient: linear-gradient(180.00deg, rgb(255, 154, 36),rgb(255, 128, 36) 100%);
$blue_light: rgb(48, 119, 255);
$dark_blue: rgb(0, 23, 82);
$blue_gradient: linear-gradient(180.00deg, rgb(0, 23, 82),rgb(0, 18, 62) 100%);
$black_bg: rgb(0, 9, 31);
$green: rgb(32, 158, 0);
$red: rgb(255, 0, 0);
$text_color: rgb(35, 35, 35);

$btn_disabled: rgb(211, 211, 211);
$text_disabled: rgb(113, 113, 113);
$light_bg: rgba(238, 238, 238, 1);

$table_bg: rgba(239, 243, 255, 1);
$table-border: rgba(0, 0, 0, 0.2);